import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Headline } from 'hudl-uniform-ui-components';
import headlinePreviewData from '../../../../data/previews/headline.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Headline"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Type" tierThree="Headline" />

      <PageNavigation>
        <PageNavigationLink>Size</PageNavigationLink>
        <PageNavigationLink>Microcopy</PageNavigationLink>
        <PageNavigationLink>Small Screens</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Size">
        <Paragraph>
          The level of headline you select should provide the appropriate
          hierarchy and visual coherency within the interface.
        </Paragraph>
        <ComponentPreview
          name="HeadlineSize"
          layout="default"
          previewData={headlinePreviewData.levels}>
          <Headline>We help coaches and athletes win with video.</Headline>
        </ComponentPreview>
      </Section>

      <Section title="Microcopy">
        <SectionSubhead>Capitalization</SectionSubhead>
        <Paragraph>
          Use our{' '}
          <Link href="/words/content-elements/labels-non-label-text">
            UI label guidelines
          </Link>{' '}
          when typesetting headlines.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="set headlines in all caps."
            img="headlinecaps-dont"
          />
          <DontDoItem
            type="do"
            text="use sentence or title case when typesetting headlines."
            img="headlinecaps-do"
          />
        </DontDo>

        <SectionSubhead>Brevity</SectionSubhead>
        <Paragraph>Keep headlines short, sweet and to the point.</Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use headlines to set large swaths of text."
            img="headlinecasing-dont"
          />
          <DontDoItem
            type="do"
            text="use strong, declarative language."
            img="headlinecasing-do"
          />
        </DontDo>
      </Section>

      <Section title="Small Screens">
        <Paragraph>
          If you use the headline component, headlines automatically decrease in
          size for screens smaller than 600px. If you’re designing exclusively
          for smaller screens, use smaller type sizes and manually provide those
          specifications to your developer.
        </Paragraph>
      </Section>

      <Section title="Platform">
        <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
          <PlatformTableRow platform="web" />
          <PlatformTableRow platform="apple" />
          <PlatformTableRow platform="android" />
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
